import React from "react";
import { Alert, Platform, SafeAreaView, StyleSheet, Text } from "react-native";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { WorthApi } from "../../../api/WorthApi";
import { Content } from "../../../components/Content";
import { RootStackParamList } from "../../../routes";
import { BinanceEditor } from "../../../components/BinanceEditor";

export function AddBinance({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddBinance">) {
  return (
    <SafeAreaView style={styles.container}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
            marginLeft: 16,
          }}
        >
          Add Binance Account
        </Text>
        <BinanceEditor
          saveButtonText="Connect Binance Account"
          onSave={(name, apiKey, apiSecret) => {
            if (apiKey && apiSecret) {
              return WorthApi.addBinanceSource(name, apiKey, apiSecret)
                .then((res) => {
                  navigation.navigate("IntegrationDetail", { id: res._id });
                })
                .catch((err) => {
                  if (Platform.OS === "web") {
                    alert(err.message);
                  } else {
                    Alert.alert(err.message);
                  }
                });
            }
            return Promise.resolve();
          }}
        />
      </Content>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 16,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 24,
    borderRadius: 8,
  },
  label: {
    margin: 16,
    fontSize: 14,
  },
  container: {
    flex: 1,
  },
});
