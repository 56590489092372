import React from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SummaryCard } from "../../../components/SummaryCard";
import { IntegrationColor } from "../../../components/IntegrationColor";
import { TransactionCard } from "../../Bank/TransactionCard";
import { useTransactions } from "../../../hooks/useTransactions";
import { useBalance } from "../../../hooks/useBalance";
import { Amount } from "../../../components/Amount";

export function AccountSummary({ integrationId }: { integrationId?: string }) {
  const transactions = useTransactions(integrationId);
  const { data: balance } = useBalance(integrationId);
  return (
    <>
      {!balance && (
        <ActivityIndicator style={{ marginTop: 24 }} color={"white"} />
      )}
      {balance && (
        <FlatList
          ListHeaderComponent={
            <>
              <SummaryCard
                title={"BANK"}
                amount={balance?.total.amount || 0}
                currency={balance?.total.currency || "EUR"}
              />
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                  marginLeft: 16,
                  marginTop: 12,
                }}
              >
                Accounts
              </Text>
              <ScrollView
                horizontal
                contentContainerStyle={{ minHeight: 84, marginBottom: 24 }}
                style={{ height: 84 }}
                showsHorizontalScrollIndicator={false}
              >
                {balance?.balances.map((balanceItem, index) => {
                  const { amount, currency } = balanceItem.balanceAmount;
                  return (
                    <TouchableOpacity
                      key={index}
                      style={{
                        width: 150,
                        backgroundColor: "#343245",
                        padding: 12,
                        margin: 12,
                        borderRadius: 8,
                      }}
                      /*  onPress={() =>
                        navigation.navigate("IntegrationDetail", {
                          id: balanceItem.integration_id,
                        })
                      } */
                      disabled={balanceItem.integration_source !== "manuel"}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: "white" }}>
                          {balanceItem.name || "Account"}
                        </Text>
                        <IntegrationColor
                          integrationId={balanceItem.integration_id}
                        />
                      </View>

                      <Text style={{ color: "white" }}>
                        <Amount
                          amount={parseFloat(amount || "0")}
                          currency={currency}
                        />
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                  marginLeft: 16,
                  marginTop: 12,
                  marginBottom: 8,
                }}
              >
                Transactions
              </Text>
            </>
          }
          data={transactions?.data}
          keyExtractor={(item, index) => item.id + "_" + index}
          renderItem={({ item }) => {
            return (
              <TransactionCard
                title={item.title}
                price={parseFloat(item.transactionAmount.amount)}
                currency={item.transactionAmount.currency}
                date={item.bookingDate}
                bank={{
                  name: item.integration_name,
                  integration_id: item.integration_id,
                }}
                type={item.type}
              />
            );
          }}
        />
      )}
    </>
  );
}
