import * as React from "react";
import {
  ActivityIndicator,
  Linking,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { BinanceIntegration } from "../api/WorthApi";
import { useTheme } from "@react-navigation/native";

export function BinanceEditor({
  integration,
  onSave,
  saveButtonText = "Save",
}: {
  integration?: BinanceIntegration;
  onSave: (name: string, apiKey: string, apiSecret: string) => Promise<void>;
  saveButtonText?: string;
}) {
  const { colors } = useTheme();

  const [loading, setLoading] = React.useState(false);

  const [name, setName] = React.useState(
    integration ? integration?.name : "Binance"
  );
  const [apiKey, setApiKey] = React.useState(integration?.data?.api_key);
  const [apiSecret, setApiSecret] = React.useState("");

  return (
    <View>
      <Text style={[{ color: colors.text, marginLeft: 16, marginBottom: 12 }]}>
        Connect your Binance account by using Binance API. Please follow{" "}
        <Text
          style={{ textDecorationLine: "underline", fontWeight: "bold" }}
          onPress={() =>
            Linking.openURL(
              "https://www.binance.com/en/support/faq/360002502072"
            )
          }
        >
          this guide
        </Text>{" "}
        for API generation. For your account security, please allow only the
        "Enable Reading" permission.
      </Text>
      <Text style={[{ color: colors.text }, styles.label]}>Name:</Text>
      <TextInput
        keyboardType="default"
        style={[
          {
            color: colors.text,
            borderColor: colors.border,
          },
          styles.input,
        ]}
        value={name}
        onChangeText={setName}
      />

      <Text style={[{ color: colors.text }, styles.label]}>API Key:</Text>
      <TextInput
        keyboardType="default"
        style={[
          {
            color: colors.text,
            borderColor: colors.border,
          },
          styles.input,
        ]}
        value={apiKey}
        onChangeText={setApiKey}
      />
      <Text style={[{ color: "white" }, styles.label]}>API Secret:</Text>
      <TextInput
        keyboardType="default"
        style={[
          {
            color: colors.text,
            borderColor: colors.border,
          },
          styles.input,
        ]}
        value={apiSecret}
        onChangeText={setApiSecret}
      />
      <TouchableOpacity
        onPress={() => {
          console.log(apiKey, apiSecret);
          if (!apiKey) {
            return;
          }
          if (!apiSecret && !integration) {
            return;
          }
          setLoading(true);
          onSave(name, apiKey, apiSecret).finally(() => setLoading(false));
        }}
        style={styles.button}
      >
        {loading ? (
          <View style={styles.buttonText}>
            <ActivityIndicator size={16} color={"white"} />
          </View>
        ) : (
          <Text
            style={[
              {
                color: colors.text,
              },
              styles.buttonText,
            ]}
          >
            {saveButtonText}
          </Text>
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 16,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 24,
    borderRadius: 8,
  },
  label: {
    margin: 16,
    fontSize: 14,
  },
  container: {
    flex: 1,
  },
});
