import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import {
  ManuelBankAccount,
  Integration,
  ManuelStockIntegration,
} from "../api/WorthApi";
import { BankAccountRow } from "../screens/Add/Bank/BankAccountRow";
import { ManuelStockRow } from "../screens/Add/Bank/ManuelStockRow";

export function ManuelStockEditor({
  integration,
  onSave,
}: {
  integration?: ManuelStockIntegration;
  onSave: (bankName: string, stocks: { [key: string]: number }) => void;
}) {
  const [name, setName] = React.useState<string>(integration?.name ?? "");
  const [stocks, setStocks] = React.useState<
    {
      symbol?: string;
      amount?: number;
    }[]
  >(
    Object.keys(integration?.data?.stocks || {}).map((s) => ({
      symbol: s,
      amount: integration?.data?.stocks?.[s],
    })) ?? [{}]
  );

  return (
    <ScrollView style={{ flex: 1 }}>
      <Text
        style={{
          color: "white",
          marginVertical: 8,
        }}
      >
        Bank Name
      </Text>
      <TextInput
        style={{
          borderColor: "white",
          borderWidth: 1,
          padding: 8,
          borderRadius: 8,
          color: "white",
        }}
        defaultValue={name}
        onChangeText={(text) => setName(text)}
      />
      <Text
        style={{
          color: "white",
          fontSize: 20,
          fontWeight: "500",
          marginTop: 24,
          marginBottom: 16,
        }}
      >
        Stocks
      </Text>
      <View style={{ flexDirection: "column" }}>
        {stocks.map((stock, index) => {
          return (
            <ManuelStockRow
              key={index}
              stock={stock}
              onUpdate={(stock) => {
                setStocks((stocks) => {
                  const newStocks = [...stocks];
                  newStocks[index] = stock;
                  return newStocks;
                });
              }}
              onDelete={() => {
                setStocks((stocks) => {
                  const newStocks = [...stocks];
                  newStocks.splice(index, 1);
                  return newStocks;
                });
              }}
            />
          );
        })}

        <TouchableOpacity
          onPress={() => {
            setStocks((stocks) => {
              const newStocks = [...stocks];
              newStocks.push({ amount: 0 });
              return newStocks;
            });
          }}
          style={[
            {
              padding: 4,
              marginVertical: 12,
              borderRadius: 8,
              flexDirection: "row",
            },
          ]}
        >
          <Text
            style={[
              {
                color: "white",
                fontWeight: "500",
              },
            ]}
          >
            Add +
          </Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => {
          if (!name) {
            return;
          }
          if (!stocks) {
            return;
          }

          const stockMap = stocks.reduce((map, cur) => {
            if (cur.symbol && cur.amount && cur.amount > 0) {
              map[cur.symbol] = cur.amount;
            }
            return map;
          }, {} as { [key: string]: number });

          onSave(name, stockMap);
        }}
        style={styles.button}
      >
        <Text
          style={[
            {
              color: "white",
            },
            styles.buttonText,
          ]}
        >
          Save
        </Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginTop: 12,
    borderRadius: 8,
    alignSelf: "flex-start",
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
