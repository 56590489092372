import React from "react";
import { View } from "react-native";

export function Content({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{ flex: 1, maxWidth: 1024, width: "96%", alignSelf: "center" }}
    >
      {children}
    </View>
  );
}
