import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { Content } from "../../../components/Content";
import { RootStackParamList } from "../../../routes";
import { WorthApi } from "../../../api/WorthApi";
import { ManuelBankEditor } from "../../../components/ManuelBankEditor";

export function AddManuelBank({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddManuelBank">) {
  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
          }}
        >
          Add Manuel Bank
        </Text>
        <ManuelBankEditor
          onSave={(bankName, accounts) => {
            WorthApi.addManualBankSource(bankName, accounts).then(() =>
              navigation.navigate("BankDetail", {
                accountId: "summary",
              })
            );
          }}
        />
      </Content>
    </View>
  );
}
