import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useQueryClient } from "react-query";
import { WorthApi } from "../../api/WorthApi";
import { RootStackParamList } from "../../routes";
import { INTEGRATION_KEY, useIntegration } from "../../hooks/useIntegration";
import { INTEGRATIONS_KEY } from "../../hooks/useIntegrations";
import { SUMMARY_KEY } from "../../hooks/useSummary";
import { Content } from "../../components/Content";
import { IntegrationCard } from "./IntegrationCard";
import { ManuelBankEditor } from "../../components/ManuelBankEditor";
import { ManuelStockEditor } from "../../components/ManuelStockEditor";
import { BinanceEditor } from "../../components/BinanceEditor";
import { AccountSummary } from "../Add/Bank/AccountSummary";

export function IntegrationDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "IntegrationDetail">) {
  const id = route.params.id;

  const { data: integration, remove } = useIntegration(id);
  const queryClient = useQueryClient();

  return (
    <ScrollView style={{ flex: 1 }}>
      <Content>
        {integration && (
          <View style={{ marginTop: 12 }}>
            <IntegrationCard integration={integration} />

            {integration.type === "bank" && integration.source === "manuel" && (
              <ManuelBankEditor
                integration={integration}
                onSave={(bankName, accounts) => {
                  WorthApi.updateManualBankSource(
                    integration._id,
                    bankName,
                    accounts
                  ).then(() => {
                    remove();
                    queryClient.removeQueries({ queryKey: INTEGRATIONS_KEY });
                    navigation.replace("Integrations");
                  });
                }}
              />
            )}

            {integration.type === "bank" &&
              integration.source === "nordigen" && (
                <AccountSummary integrationId={integration._id} />
              )}
            {integration.type === "stock" &&
              integration.source === "manuel" && (
                <ManuelStockEditor
                  integration={integration}
                  onSave={(name, stocks) => {
                    WorthApi.updateManualStockSource(
                      integration._id,
                      name,
                      stocks
                    ).then(() => {
                      remove();
                      queryClient.removeQueries({ queryKey: INTEGRATIONS_KEY });
                      navigation.replace("Integrations");
                    });
                  }}
                />
              )}

            {integration.type === "crypto" &&
              integration.source === "binance" && (
                <BinanceEditor
                  integration={integration}
                  onSave={(name, apiKey, apiSecret) => {
                    return WorthApi.updateBinanceSource(
                      integration._id,
                      name,
                      apiKey,
                      apiSecret
                    ).then(() => {
                      remove();
                      queryClient.removeQueries({ queryKey: INTEGRATIONS_KEY });
                      navigation.replace("Integrations");
                    });
                  }}
                />
              )}
            <TouchableOpacity
              onPress={() => {
                WorthApi.deleteIntegration(id).then(() => {
                  queryClient.removeQueries({ queryKey: INTEGRATIONS_KEY });
                  queryClient.removeQueries({ queryKey: SUMMARY_KEY });

                  navigation.replace("Integrations");
                });
              }}
              style={[
                styles.button,
                {
                  padding: 4,
                  marginVertical: 6,
                  borderRadius: 8,
                  flexDirection: "row",
                  marginTop: 20,
                },
              ]}
            >
              <Text
                style={[
                  {
                    color: "#ff0000",
                    fontWeight: "500",
                  },
                  styles.buttonText,
                ]}
              >
                Delete Integration
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </Content>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  valueInput: {
    flex: 1,
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,
    borderRadius: 6,
  },
  keyInput: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  container: { flex: 1, alignItems: "flex-start" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    borderRadius: 8,
    flexDirection: "row",
  },
  buttonText: {
    margin: 12,
  },
  type: { fontSize: 16, marginBottom: 4, color: "white" },
});
