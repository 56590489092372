import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useTransactions(integrationId?: string) {
  return useQuery(
    ["bankTransactions", integrationId],
    () => WorthApi.getTransactions(integrationId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}
