import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { AddIntegrationButton } from "../../components/AddIntegrationButton";
import { Content } from "../../components/Content";
import { useIntegrations } from "../../hooks/useIntegrations";
import { RootStackParamList } from "../../routes";
import { IntegrationCard } from "./IntegrationCard";
import { getIcon } from "../../components/Icon";

export function Integrations({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Integrations">) {
  const { data } = useIntegrations();
  const sortedIntegrations = data?.sort((a, b) =>
    (a.type + "").localeCompare(b.type)
  );
  return (
    <View style={{ flex: 1 }}>
      <Content>
        <FlatList
          data={sortedIntegrations}
          style={{ marginTop: 12 }}
          renderItem={({ item: integration, index }) => {
            return (
              <>
                {(index === 0 ||
                  sortedIntegrations?.[index - 1].type !==
                    integration.type) && (
                  <>
                    {integration.type === "bank" && (
                      <Text style={styles.section}>{getIcon("BANK")} BANK</Text>
                    )}
                    {integration.type === "stock" && (
                      <Text style={styles.section}>
                        {getIcon("STOCK")} STOCK
                      </Text>
                    )}
                    {integration.type === "crypto" && (
                      <Text style={styles.section}>
                        {getIcon("CRYPTO")} CRYPTO
                      </Text>
                    )}
                  </>
                )}
                <IntegrationCard
                  integration={integration}
                  onPress={() =>
                    navigation.navigate("IntegrationDetail", {
                      id: integration._id,
                    })
                  }
                />
              </>
            );
          }}
          keyExtractor={(item) => item._id}
        />
      </Content>
      <AddIntegrationButton navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  type: { marginLeft: 6, fontSize: 16, color: "white" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
  section: { marginLeft: 16, marginVertical: 12, color: "white" },
});
