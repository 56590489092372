import * as React from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { ManuelBankAccount, ManuelBankIntegration } from "../api/WorthApi";
import { BankAccountRow } from "../screens/Add/Bank/BankAccountRow";

export function ManuelBankEditor({
  integration,
  onSave,
}: {
  integration?: ManuelBankIntegration;
  onSave: (bankName: string, accounts: ManuelBankAccount[]) => void;
}) {
  const [bankName, setBankName] = React.useState<string>(
    integration?.name ?? ""
  );
  const [accounts, setAccounts] = React.useState<Partial<ManuelBankAccount>[]>(
    integration?.data?.accounts ?? [{}]
  );

  return (
    <ScrollView style={{ flex: 1 }}>
      <Text
        style={{
          color: "white",
          marginVertical: 8,
        }}
      >
        Bank Name
      </Text>
      <TextInput
        style={{
          borderColor: "white",
          borderWidth: 1,
          padding: 8,
          borderRadius: 8,
          color: "white",
        }}
        defaultValue={bankName}
        onChangeText={(text) => setBankName(text)}
      />
      <Text
        style={{
          color: "white",
          fontSize: 20,
          fontWeight: "500",
          marginTop: 24,
          marginBottom: 16,
        }}
      >
        Accounts
      </Text>
      <View style={{ flexDirection: "column" }}>
        {accounts.map((account, index) => {
          return (
            <BankAccountRow
              key={index}
              account={account}
              onUpdate={(account) => {
                setAccounts((accounts) => {
                  const newAccounts = [...accounts];
                  newAccounts[index] = account;
                  return newAccounts;
                });
              }}
              onDelete={() => {
                setAccounts((accounts) => {
                  const newAccounts = [...accounts];
                  newAccounts.splice(index, 1);
                  return newAccounts;
                });
              }}
            />
          );
        })}

        <TouchableOpacity
          onPress={() => {
            setAccounts((accounts) => {
              const newAccounts = [...accounts];
              newAccounts.push({ amount: 0, currency: "EUR" });
              return newAccounts;
            });
          }}
          style={[
            {
              padding: 4,
              marginVertical: 12,
              borderRadius: 8,
              flexDirection: "row",
            },
          ]}
        >
          <Text
            style={[
              {
                color: "white",
                fontWeight: "500",
              },
            ]}
          >
            Add +
          </Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={() => {
          if (!bankName) {
            return;
          }
          if (!accounts) {
            return;
          }

          onSave(bankName, accounts as ManuelBankAccount[]);
        }}
        style={styles.button}
      >
        <Text
          style={[
            {
              color: "white",
            },
            styles.buttonText,
          ]}
        >
          Save
        </Text>
      </TouchableOpacity>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginTop: 12,
    borderRadius: 8,
    alignSelf: "flex-start",
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
