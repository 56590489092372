import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useBalance(integrationId?: string) {
  return useQuery(
    ["bankAccountBalance", integrationId],
    () => WorthApi.getAccountBalance(integrationId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useBalanceOfIntegration(integrationId: string) {
  return useQuery(
    ["bankAccountBalance", integrationId],
    () => WorthApi.getAccountBalance(integrationId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}
