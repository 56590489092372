import * as React from "react";

import { useTheme } from "@react-navigation/native";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { useStockPrices } from "../../hooks/useStockPrices";
import { TextInput } from "react-native-gesture-handler";
import { Stock, WorthApi } from "../../api/WorthApi";
import { queryClient } from "../../queryClient";

export function StockUpdateCard({
  symbol,
  detail,
  onUpdate,
}: {
  symbol: string;
  detail: Stock["details"][0];
  onUpdate: () => void;
}) {
  const { colors } = useTheme();
  const stockPrices = useStockPrices();
  const [count, setCount] = React.useState<string | undefined>();

  return (
    <View>
      {stockPrices?.state === "ready" && (
        <>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flexDirection: "column" }}>
              <Text style={[{ color: colors.text }, styles.countLabel]}>
                Account:
              </Text>

              <Text style={[{ color: colors.text }, styles.countLabel]}>
                {detail.name || "Manuel"}
              </Text>
            </View>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <Text style={[{ color: colors.text }, styles.countLabel]}>
                Count:
              </Text>
              <TextInput
                keyboardType="number-pad"
                style={[
                  {
                    color: colors.text,
                    borderColor: colors.border,
                  },
                  styles.input,
                ]}
                defaultValue={detail?.amount + ""}
                value={count}
                onChangeText={(text) => setCount(text)}
              />
            </View>

            <TouchableOpacity
              onPress={() => {
                if (!symbol || !count) {
                  return;
                }
                WorthApi.updateStock(
                  detail.integration_id,
                  symbol,
                  parseFloat(count)
                ).then(() => {
                  queryClient.removeQueries({ queryKey: "stockPrices" });
                  onUpdate();
                });
              }}
              style={styles.button}
            >
              <Text
                style={[
                  {
                    color: colors.text,
                  },
                  styles.buttonText,
                ]}
              >
                Save
              </Text>
            </TouchableOpacity>
          </View>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
    alignSelf: "flex-end",
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
