import * as React from "react";

import { Text, TextInput, TouchableOpacity, View } from "react-native";
import { ManuelBankAccount } from "../../../api/WorthApi";

export function BankAccountRow({
  account,
  onUpdate,
  onDelete,
}: {
  account: Partial<ManuelBankAccount>;
  onUpdate: (account: Partial<ManuelBankAccount>) => void;
  onDelete: () => void;
}) {
  return (
    <View style={{ flexDirection: "row", flex: 1 }}>
      <View>
        <Text
          style={{
            color: "white",
            marginVertical: 8,
          }}
        >
          Account Name
        </Text>
        <TextInput
          style={{
            borderColor: "white",
            borderWidth: 1,
            padding: 8,
            borderRadius: 8,
            color: "white",
          }}
          defaultValue={account.name}
          onChangeText={(text) => onUpdate({ ...account, name: text })}
        />
      </View>
      <View style={{ marginHorizontal: 20 }}>
        <Text
          style={{
            color: "white",
            marginVertical: 8,
          }}
        >
          Amount
        </Text>
        <TextInput
          style={{
            borderColor: "white",
            borderWidth: 1,
            padding: 8,
            borderRadius: 8,
            color: "white",
          }}
          keyboardType="numeric"
          defaultValue={account.amount + ""}
          onChangeText={(text) =>
            onUpdate({ ...account, amount: parseFloat(text) })
          }
        />
      </View>
      <View>
        <Text
          style={{
            color: "white",
            marginVertical: 8,
          }}
        >
          Currency
        </Text>
        <TextInput
          style={{
            borderColor: "white",
            borderWidth: 1,
            padding: 8,
            borderRadius: 8,
            color: "white",
          }}
          defaultValue={account.currency}
          onChangeText={(text) => onUpdate({ ...account, currency: text })}
        />
      </View>
      <TouchableOpacity
        onPress={onDelete}
        style={[
          {
            padding: 4,
            marginVertical: 6,
            marginHorizontal: 12,
            borderRadius: 8,
            flexDirection: "row",
            alignSelf: "flex-end",
          },
        ]}
      >
        <Text
          style={[
            {
              color: "#ff0000",
              fontWeight: "500",
            },
          ]}
        >
          Delete
        </Text>
      </TouchableOpacity>
    </View>
  );
}
