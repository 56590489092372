import { Quote } from "./StockApi";

export interface Summary {
  total: { amount: number; currency: string };
  items: SummaryItem[];
  failedIntegrations: { id: string; name: string }[];
}

export interface SummaryItem {
  title: string;
  type: "bank" | "crypto" | "stock";
  total: { amount: number; currency: string };
}

export interface TokenResponse {
  id: string;
  name: string;
  email: string;
  picture: string;
  accessToken: string;
}

export interface RefreshTokenResponse {
  id: string;
  accessToken: string;
}
export interface Institution {
  name: string;
  id: string;
  logo: string;
}

export interface AccountBalanceResponse {
  total: { amount: number; currency: string };
  balances: {
    balanceAmount: { amount: string; currency: string };
    name?: string;
    integration_id: string;
    integration_source: string;
  }[];
}

export type TransactionType = "booked" | "pending";

export interface Transaction {
  id: string;
  integration_id: string;
  integration_name: string;
  title: string;
  bookingDate: string;
  transactionAmount: {
    amount: string;
    currency: string;
  };
  type: TransactionType;
}

export interface CryptoSummaryResponse {
  total: { amount: number; currency: string };
  accounts: Account[];
  items: Coin[];
}

export type Stock = Quote & {
  count: number;
  details: { integration_id: string; name: string; amount: number }[];
};
export interface StockSummaryResponse {
  total: { amount: number; currency: string };
  accounts: Account[];
  stocks: Stock[];
}

export interface Coin {
  symbol: string;
  amount: number;
  price: number;
  currency: "EUR";
  details: { integration_id: string; name: string; amount: number }[];
}

export interface Account {
  integration_id: string;
  name: string;
  amount: number;
  currency: "EUR";
}

export interface ManuelBankAccount {
  name: string;
  amount: number;
  currency: string;
}

export interface BaseIntegration {
  _id: string;
  name: string;
  updatedAt: string;
}

export type BinanceIntegration = BaseIntegration & {
  type: "crypto";
  source: "binance";
  data: {
    api_key: string;
  };
};
export type ManuelStockIntegration = BaseIntegration & {
  type: "stock";
  source: "manuel";
  data: {
    stocks: { [key: string]: number };
  };
};

export type ManuelBankIntegration = BaseIntegration & {
  type: "bank";
  source: "manuel";
  data: {
    accounts: ManuelBankAccount[];
  };
};

export type BankIntegration = BaseIntegration & {
  type: "bank";
  source: "nordigen";
  data: {};
};

export type Integration =
  | ManuelBankIntegration
  | ManuelStockIntegration
  | BinanceIntegration
  | BankIntegration;

class WorthApiClass {
  // baseURL = "http://localhost:3005";
  baseURL = "https://api.worth.cancit.com";
  /*  baseURL =
    Platform.OS === "web"
      ? "http://localhost:3005"
      : "http://192.168.1.137:3005"; */

  token = "";

  googleLogin(token: string): Promise<TokenResponse> {
    const headers = new Headers();
    headers.append("accept", "application/json");
    headers.append("Content-Type", "application/json");

    return fetch(this.baseURL + "/auth/google", {
      headers,
      method: "POST",
      body: JSON.stringify({ token }),
    }).then((res) => res.json());
  }

  getHeaders() {
    const headers = new Headers();
    headers.append("accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("worth-access-token", this.token);
    return headers;
  }

  getAccountBalance(integrationId?: string): Promise<AccountBalanceResponse> {
    if (integrationId) {
      return this.get(`/summary/bank/balance?integration_id=${integrationId}`);
    }
    return this.get("/summary/bank/balance");
  }

  getTransactions(integrationId?: string): Promise<Transaction[]> {
    if (integrationId) {
      return this.get(
        `/summary/bank/transactions?integration_id=${integrationId}`
      );
    }

    return this.get("/summary/bank/transactions");
  }

  getSummary(): Promise<Summary> {
    return this.get("/worth");
  }

  getStockSummary(): Promise<StockSummaryResponse> {
    return this.get("/summary/stock");
  }

  getCrypto(): Promise<CryptoSummaryResponse> {
    return this.get("/summary/crypto");
  }

  getInstitutions(country: string): Promise<Institution[]> {
    return this.get("/summary/bank/institutions?country=" + country);
  }

  getIntegrations(): Promise<any[]> {
    return this.get("/integrations");
  }

  getIntegration(id: string): Promise<Integration> {
    return this.get("/integrations/" + id);
  }

  deleteIntegration(id: string): Promise<any[]> {
    return this.delete("/integrations/" + id);
  }

  addBankSource(id: string, name: string): Promise<{ link: string }> {
    return this.post("/integrations", {
      type: "bank",
      source: "nordigen",
      name,
      institution_id: id,
    });
  }

  addManualBankSource(
    name: string,
    accounts: ManuelBankAccount[]
  ): Promise<{ link: string }> {
    return this.post("/integrations", {
      type: "bank",
      source: "manuel",
      name,
      accounts,
    });
  }

  updateManualBankSource(
    integrationId: string,
    name: string,
    accounts: ManuelBankAccount[]
  ): Promise<{ _id: string }> {
    return this.post("/integrations/" + integrationId, {
      type: "bank",
      source: "manuel",
      name,
      accounts,
    });
  }

  updateManualStockSource(
    integrationId: string,
    name: string,
    stocks: { [key: string]: number }
  ): Promise<{ _id: string }> {
    return this.post("/integrations/" + integrationId, {
      type: "stock",
      source: "manuel",
      name,
      stocks,
    });
  }

  updateBinanceSource(
    integrationId: string,
    name: string,
    apiKey: string,
    apiSecret: string
  ): Promise<{ _id: string }> {
    return this.post("/integrations/" + integrationId, {
      type: "crypto",
      source: "binance",
      name,
      api_key: apiKey,
      api_secret: apiSecret ?? undefined,
    });
  }

  addCryptoWalletSource(
    publicKey: string,
    source: "avax" | "solana"
  ): Promise<{ link: string }> {
    return this.post("/integrations", {
      type: "crypto",
      source: source,
      wallet: publicKey,
    });
  }

  searchStock(query: string): Promise<any[]> {
    return this.get("/stock?query=" + encodeURIComponent(query));
  }

  getStock(symbol: string): Promise<any> {
    return this.get("/stock/" + encodeURIComponent(symbol));
  }

  getWalletBalance(publicKey: string, currency: string): Promise<any> {
    return this.get(
      "/wallet/" +
        encodeURIComponent(publicKey) +
        "?currency=" +
        encodeURIComponent(currency)
    );
  }

  addStockAccount(
    name: string,
    symbol: string,
    count: number
  ): Promise<{ link: string }> {
    return this.post("/integrations", {
      type: "stock",
      source: "manuel",
      name,
      symbol,
      count,
    });
  }

  updateStock(
    integration_id: string,
    symbol: string,
    count: number
  ): Promise<{ link: string }> {
    return this.post("/integrations/" + integration_id, {
      type: "stock",
      source: "manuel",
      symbol,
      count,
    });
  }

  addBinanceSource(
    name: string,
    apiKey: string,
    apiSecret: string
  ): Promise<{ _id: string }> {
    return this.post("/integrations", {
      type: "crypto",
      source: "binance",
      name,
      api_key: apiKey,
      api_secret: apiSecret,
    });
  }

  refreshToken(): Promise<RefreshTokenResponse> {
    return this.post("/token/refresh", {}).then((res: RefreshTokenResponse) => {
      this.token = res.accessToken;
      return res;
    });
  }

  get(path: string) {
    const headers = this.getHeaders();
    return new Promise<any>((resolve, reject) => {
      fetch(this.baseURL + path, {
        headers,
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return resolve(await res.json());
        }
        return reject(await res.json());
      });
    });
  }

  delete(path: string) {
    const headers = this.getHeaders();
    return new Promise<any>((resolve, reject) => {
      fetch(this.baseURL + path, {
        headers,
        method: "DELETE",
      }).then(async (res) => {
        if (res.ok) {
          return resolve(await res.json());
        }
        return reject(await res.json());
      });
    });
  }

  post(path: string, body: object) {
    const headers = this.getHeaders();
    return new Promise<any>((resolve, reject) => {
      fetch(this.baseURL + path, {
        headers,
        method: "POST",
        body: JSON.stringify(body),
      }).then(async (res) => {
        if (res.ok) {
          return resolve(await res.json());
        }
        return reject(await res.json());
      });
    });
  }
}

export const WorthApi = new WorthApiClass();
