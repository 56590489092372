import { useQuery } from "react-query";
import { StockSummaryResponse, WorthApi } from "../api/WorthApi";

export function useStockPrices(): {
  state: "ready" | "loading";
} & Partial<StockSummaryResponse> {
  const prices = useQuery("stockPrices", () => WorthApi.getStockSummary(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { state: prices.isFetched ? "ready" : "loading", ...prices?.data };
}
