import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, View } from "react-native";

import { RootStackParamList } from "../../routes";
import { Content } from "../../components/Content";
import { AccountSummary } from "../Add/Bank/AccountSummary";

export function BankDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "BankDetail">) {
  return (
    <View style={styles.container}>
      <Content>
        <AccountSummary />
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
