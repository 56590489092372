import * as React from "react";

import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { RootStackParamList } from "../../../routes";
import { StockCard } from "../../Stock/StockCard";
import { TextInput } from "react-native-gesture-handler";
import { WorthApi } from "../../../api/WorthApi";
import { useQuery, useQueryClient } from "react-query";
import { Content } from "../../../components/Content";
import { useStockPrices } from "../../../hooks/useStockPrices";
import { StockUpdateCard } from "../../Stock/StockUpdateCard";

export function AddStock({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddStock">) {
  const { colors } = useTheme();
  const symbol = route.params.symbol;
  const [count, setCount] = React.useState<string>("1");
  const [accountName, setAccountName] = React.useState<string>();

  const queryClient = useQueryClient();
  const { accounts, stocks } = useStockPrices();

  const existingStock = stocks?.find((s) => s.symbol === symbol)!;

  const { data } = useQuery(
    ["addStockPrice", symbol],
    () => WorthApi.getStock(symbol),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <View style={{ flex: 1 }}>
      <Content>
        {data && (
          <>
            <StockCard
              title={data.longName || data.shortName}
              price={data.regularMarketPrice}
              count={parseFloat(count)}
              changePercentage={data.regularMarketChangePercent}
              currency={data.currency}
            />
            {accounts?.map((account) => (
              <StockUpdateCard
                symbol={symbol}
                detail={{
                  integration_id: account.integration_id,
                  name: account.name,
                  amount:
                    existingStock?.details?.find(
                      (d) => d.integration_id === account.integration_id
                    )?.amount ?? 0,
                }}
                onUpdate={() => navigation.replace("Stock")}
              />
            ))}
            <Text style={[{ color: colors.text }, styles.countLabel]}>
              New Account Name:
            </Text>
            <TextInput
              keyboardType="number-pad"
              style={[
                {
                  color: colors.text,
                  borderColor: colors.border,
                },
                styles.input,
              ]}
              defaultValue={accountName}
              value={accountName}
              onChangeText={(text) => setAccountName(text)}
            />
            <Text style={[{ color: colors.text }, styles.countLabel]}>
              Count:
            </Text>
            <TextInput
              keyboardType="number-pad"
              style={[
                {
                  color: colors.text,
                  borderColor: colors.border,
                },
                styles.input,
              ]}
              defaultValue={"1"}
              value={count}
              onChangeText={(text) => setCount(text)}
            />
            <TouchableOpacity
              onPress={() => {
                if (!symbol || !count || !accountName) {
                  return;
                }
                WorthApi.addStockAccount(
                  accountName,
                  symbol,
                  parseFloat(count)
                ).then(() => {
                  queryClient.removeQueries({ queryKey: "stockPrices" });
                  navigation.replace("Stock");
                });
              }}
              style={styles.button}
            >
              <Text
                style={[
                  {
                    color: colors.text,
                  },
                  styles.buttonText,
                ]}
              >
                Create
              </Text>
            </TouchableOpacity>
          </>
        )}
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
