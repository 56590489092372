import React from "react";
import { ScrollView, Text, View } from "react-native";
import { Account } from "../api/WorthApi";
import { IntegrationColor } from "./IntegrationColor";
import { Amount } from "./Amount";

export function AccountList({ accounts }: { accounts: Account[] }) {
  return (
    <>
      <Text
        style={{
          color: "white",
          fontSize: 20,
          marginLeft: 16,
          marginTop: 12,
        }}
      >
        Accounts
      </Text>
      <ScrollView
        horizontal
        contentContainerStyle={{ minHeight: 84, marginBottom: 24 }}
        style={{ height: 84 }}
        showsHorizontalScrollIndicator={false}
      >
        {accounts?.map((account, index) => {
          const { amount, currency } = account;

          return (
            <View
              key={index}
              style={{
                width: 150,
                backgroundColor: "#343245",
                padding: 12,
                margin: 12,
                borderRadius: 8,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ color: "white" }}>
                  {account.name || "Account"}
                </Text>
                <IntegrationColor integrationId={account.integration_id} />
              </View>

              <Text style={{ color: "white" }}>
                <Amount amount={amount} currency={currency} />
              </Text>
            </View>
          );
        })}
      </ScrollView>
    </>
  );
}
