import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View } from "react-native";

import { RootStackParamList } from "../../routes";
import { useStockPrices } from "../../hooks/useStockPrices";
import { StockCard } from "./StockCard";
import { StockUpdateCard } from "./StockUpdateCard";
import { Content } from "../../components/Content";

export function StockDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "StockDetail">) {
  const stockPrices = useStockPrices();
  const { symbol } = route.params;
  const s = stockPrices?.stocks?.find((s) => s.symbol === route.params.symbol)!;
  return (
    <View>
      <Content>
        {s && stockPrices?.state === "ready" && (
          <>
            <StockCard
              title={s.longName || s.shortName}
              price={s.regularMarketPrice}
              count={s.count}
              changePercentage={s.regularMarketChangePercent}
              currency={s.currency}
            />
            {s.details.map((s) => (
              <StockUpdateCard
                symbol={symbol}
                detail={s}
                onUpdate={() => navigation.replace("Stock")}
              />
            ))}
          </>
        )}
      </Content>
    </View>
  );
}
