import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../routes";
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { CoinCard } from "./CoinCard";
import { SummaryCard } from "../../components/SummaryCard";
import { Content } from "../../components/Content";
import { useCryptoSummary } from "../../hooks/useCryptoSummary";
import { AccountList } from "../../components/AccountList";

export function Crypto({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Crypto">) {
  const { data: summary } = useCryptoSummary();

  return (
    <View style={styles.container}>
      <Content>
        {!summary && <ActivityIndicator style={styles.loading} color="white" />}

        <FlatList
          ListHeaderComponent={
            summary?.total && (
              <>
                <SummaryCard
                  title={"CRYPTO"}
                  amount={summary?.total.amount}
                  currency={summary?.total.currency}
                />
                <AccountList accounts={summary?.accounts} />
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    marginLeft: 16,
                    marginVertical: 12,
                  }}
                >
                  Coins
                </Text>
              </>
            )
          }
          data={summary?.items}
          keyExtractor={(item) => item.symbol}
          renderItem={({ item }) => (
            <CoinCard
              title={item.symbol}
              count={item.amount}
              price={item.price}
              details={item.details}
              currency={"EUR"}
            />
          )}
        />
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  loading: { marginTop: 12 },
  container: { flex: 1 },
});
